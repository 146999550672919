import React from 'react';
import classNames from 'classnames';

import SB3Downloader from '../../containers/sb3-downloader.jsx';

import Box from '../box/box.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import Modal from '../../containers/modal.jsx';

import File from './file.jsx';

import styles from './file-modal.css';

const FileModalComponent = props => {
    let label = '';
    if(props.phase === 'open') label = "Open Remote Scratch File";
    if(props.phase === 'save') label = "Save Scratch File";
    if(props.phase === 'saveRemote') label = "Save Remote Scratch File";

    return (
        <Modal
            className={styles.modalContent}
            contentLabel={label}
            headerClassName={styles.header}
            headerImage={props.smallPeripheralImage}
            id="fileModal"
            onHelp={props.onHelp}
            onRequestClose={props.onCancel}
        >
            <Box className={styles.body}>
                {/* {
                    props.phase === 'open' && <>
                        <div className={styles.bottomAreaDirectIp}>
                            <input className={styles.ipInput} placeholder="Search username" value={props.searchUser} onChange={props.handleSearchUserChange}/>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleOnSearch}
                            >
                                Search
                            </button>
                        </div>
                        <div style={{borderTop: '1px solid black', margin: '10px 0'}}/>
                        {
                            props.fileError !== '' && (
                                <span style={{color: 'red', fontSize: '0.8rem'}}>Error: {props.fileError}</span>
                            )
                        }
                        {
                            !props.loadingFile && (
                                <div className={styles.fileList}>
                                    {
                                        props.files.filter(file => file.extension === 'sb3').map(file => (
                                            <File file={file} handleClickFile={props.handleClickFile} />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            props.loadingFile && (
                                <div className={styles.fileList} style={{padding: '10px'}}>
                                    Loading File...
                                </div>
                            )
                        }
                    </>
                } */}
                {
                    props.phase === 'save' && <>
                        <div style={{background: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingTop: '10px'}} />
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                                <span style={{fontSize: '15px'}}>Project Name</span>
                                <ProjectTitleInput onUpdateProjectTitle={(title) => props.setProjectTitle(title)}/>
                            </div>
                            <SB3Downloader vm={props.vm}>
                                {(className, downloadProject) => (
                                    <button
                                        className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                        style={{padding: '10px', paddingLeft: '50px', paddingRight: '50px'}}
                                        onClick={downloadProject}
                                    >
                                        Save
                                    </button> 
                                )}
                            </SB3Downloader>
                            <div style={{paddingBottom: '10px'}} />
                        </div>
                    </>
                }
                {/* {
                    props.phase === 'saveRemote' && !props.newUser && <>
                        <div style={{background: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingTop: '10px'}} />
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleOnClickCreateUser}
                                style={{width: '200px', justifyContent: 'center'}}
                            >
                                <span>
                                    Create New User
                                </span>
                            </button> 
                            <div style={{borderBottom: 'solid 1px black', paddingTop: '5px', marginBottom: '5px', width: '100%'}} />
                            <h4 style={{textAlign: 'center', margin: '10px'}}>Existing User</h4>
                            {
                                props.fileError !== '' && (
                                    <span style={{color: 'red', fontSize: '0.8rem'}}>Error: {props.fileError}</span>
                                )
                            }
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span style={{fontSize: '15px'}}>Username</span>
                                <input className={styles.ipInput} placeholder="username" value={props.searchUser} onChange={props.handleSearchUserChange}/>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                                <span style={{fontSize: '15px'}}>Project Name</span>
                                <ProjectTitleInput onUpdateProjectTitle={(title) => props.setProjectTitle(title)}/>
                            </div>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                style={{padding: '10px', paddingLeft: '50px', paddingRight: '50px'}}
                                onClick={props.handleOnExistingUserSave}
                            >
                                Save
                            </button> 
                            <div style={{paddingBottom: '10px'}} />
                        </div>
                    </>
                }
                {
                    props.phase === 'saveRemote' && props.newUser && <>
                        <div style={{paddingTop: '5px'}}>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleOnClickBack}
                            >
                                Back
                            </button>
                        </div>
                        <div style={{background: '#FFFFFF', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingTop: '10px'}} />
                            <h4 style={{textAlign: 'center', marginTop: 0}}>New User</h4>
                            {
                                props.fileError !== '' && (
                                    <span style={{color: 'red', fontSize: '0.8rem'}}>Error: {props.fileError}</span>
                                )
                            }
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span style={{fontSize: '15px'}}>Username</span>
                                <input className={styles.ipInput} placeholder="username" value={props.searchUser} onChange={props.handleSearchUserChange}/>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                                <span style={{fontSize: '15px'}}>Project Name</span>
                                <ProjectTitleInput onUpdateProjectTitle={(title) => props.setProjectTitle(title)}/>
                            </div>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                style={{padding: '10px', paddingLeft: '50px', paddingRight: '50px'}}
                                onClick={props.handleOnNewUserSave}
                            >
                                Save
                            </button>
                            <div style={{paddingBottom: '10px'}} />
                        </div>
                    </>
                } */}
            </Box>
        </Modal>
    )
}

export {
    FileModalComponent as default
};