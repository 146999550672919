import PropTypes from 'prop-types';
import React from 'react';

import gamepadStyles from  './gamepad-mode.css';
import menubarStyles from './menu-bar.css';

const styles = {
    ...gamepadStyles,
    ...menubarStyles
}

class GamepadModeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.gamepadTypes = props.vm.runtime.peripheralExtensions.ScratchLink._extension.GAMEPAD_TYPES;

        this.state = {
            gamepadMode: props.vm.runtime.peripheralExtensions.ScratchLink._extension.gamepadMode
        };

        this.handleChange = this.handleChange.bind(this);
        this.onProjectChanged = this.onProjectChanged.bind(this);
    }

    componentDidMount() {
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
    }

    componentWillUnmount() {
        this.props.vm.removeListener('PROJECT_CHANGED', this.onProjectChanged);
    }

    onProjectChanged() {
        this.setState({
            gamepadMode: this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.gamepadMode
        })
    }

    handleChange(e) {
        this.setState({
            gamepadMode: e.target.value
        });
        this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.changeGamepadMode(e.target.value);
        this.props.onSelectionChanged();
    }

    render() {
        return (
            <select
                aria-label='Gamepad Type'
                className={styles.gamepadModeSelect}
                value={this.state.gamepadMode}
                onChange={this.handleChange}
            >
                <option value="" hidden></option>
                {
                    Object.keys(this.gamepadTypes).map(gamepadType => {
                        return (
                            <option key={this.gamepadTypes[gamepadType]} value={this.gamepadTypes[gamepadType]}>
                                {this.gamepadTypes[gamepadType]}
                            </option>
                        )
                    })
                }
            </select>
        )
    }
}

GamepadModeSelector.propTypes = {

};

export default GamepadModeSelector;
