import React from 'react';
import PropTypes from 'prop-types';
import VM from 'scratch3-vm-scratchlink';
import {connect} from 'react-redux';

import HardwareModeSelector from './hardware-mode.jsx';
import BlockLevelModeSelector from './block-level-mode.jsx';
import GamepadModeSelector from './gamepad-mode.jsx';

class ScratchLinkModes extends React.Component{
    constructor(props) {
        super(props);
        this.onProjectChanged = this.onProjectChanged.bind(this);

        this.state = {
            examplarSave: false,
            allowHardwareChange: true,
            allowGamepadChange: true
        }

        this.handleOnClick = this.handleOnClick.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onConnectionChanged = this.onConnectionChanged.bind(this);
    }

    componentDidMount() {
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
        this.props.vm.addListener('SCRATCHLINK_CONNECTION_CHANGED', this.onConnectionChanged);
    }

    onProjectChanged() {
        this.forceUpdate();
    }

    onConnectionChanged(deviceInfo) {
        this.setState({
            allowHardwareChange: !deviceInfo.hardwareConnected,
            allowGamepadChange: !deviceInfo.gamepad1Connected && !deviceInfo.gamepad2Connected
        })
    }

    handleOnClick(link) {
        navigator.clipboard.writeText(link).then(() => window.alert(`Link Copied!\n\n${link}`)).catch(() => window.alert(link));
    }

    onSelectionChanged() {
        this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.checkIllegalBlocks();
    }

    render() {
        if(!this.props.scratchLinkExtensionLoaded) return <div></div>;

        const gamepadMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.gamepadMode;
        const hardwareMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.hardwareMode;
        let challengeType = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.challengeType;
        const blockLevelMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.blockLevelMode;
        let projectType = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.projectType;

        if(!hardwareMode) return <div />;

        const newProjectLink = `${window.location.origin}/#/newProject/${gamepadMode}/${hardwareMode}/${challengeType}/${projectType}/${blockLevelMode}`;

        if(projectType === this.props.vm.ProjectTypes.CUSTOM || projectType === this.props.vm.ProjectTypes.CODINGPROJECT) return (
            <>
                <span onClick={() => this.handleOnClick(newProjectLink)} style={{fontSize: '1.2em', fontWeight: 700, color: '#F3942F', marginRight: '5px'}}>Blocks: </span>
                <div style={{color: '#eee', display: 'flex', alignItems: 'center'}}>
                    {
                        this.state.allowGamepadChange ? <GamepadModeSelector vm={this.props.vm} onSelectionChanged={this.onSelectionChanged}/> : ` ${gamepadMode} `
                    }|
                    {
                        !hardwareMode.includes('Simulator') && this.state.allowHardwareChange ? <HardwareModeSelector vm={this.props.vm} onSelectionChanged={this.onSelectionChanged}/> : ` ${hardwareMode} `
                    }| 
                    <BlockLevelModeSelector vm={this.props.vm} allowChange={this.state.allowGamepadChange && this.state.allowHardwareChange} onSelectionChanged={this.onSelectionChanged}/>
                </div>
            </>
        )

        return (
            <>
                <span onClick={() => this.handleOnClick(newProjectLink)} style={{fontSize: '1.2em', fontWeight: 700, color: '#F3942F', marginRight: '5px'}}>Blocks: </span>
                <div style={{color: '#eee'}}>{gamepadMode} | {hardwareMode} | {blockLevelMode} | {projectType} | {challengeType}</div>
            </>
        )
    }
}

ScratchLinkModes.propTypes = {
    styles: PropTypes.object.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    scratchLinkExtensionLoaded: state.scratchGui.scratchlink.extensionLoaded
});

const mapDispatchToProps = dispatch => ({
    setProjectTitle: (title) => dispatch(setProjectTitle(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScratchLinkModes);