import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ProjectLoadModalComponent, { PHASES } from '../components/project-load-modal/project-load-modal.jsx';
import VM from 'scratch3-vm-scratchlink';
import extensionData from '../lib/libraries/extensions/index.jsx';
import {connect} from 'react-redux';
import {closeProjectLoadModal} from '../reducers/modals';
import {requestNewProject} from '../reducers/project-state'

//const hardwareImage1 = require('../components/project-load-modal/hardware-images/searching.png');

import {
    setProjectTitle
} from '../reducers/project-title';

class ProjectLoadModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickGamepadType',
            'handleClickCodingType',
            'handleClickHardwareType',
            'handleClickChallengeType',
            'handleClickExampleType',
            'handleClickProjectType',
            'handleClickBlockLevel',
            'handleOnBack',
        ]);

        this.state = {
            phase: PHASES.gamepadSelecting,
            extension: props.vm.runtime.peripheralExtensions.ScratchLink._extension,
            hardwareImages: {
                //[props.vm.HardwareTypes.ebot_sim]: hardwareImage1
            },
            gamepadSelected: null,
            codingTypeSelected: null,
            projectTypeSelected: null,
            hardwareSelected: null,
            challengeSelected: null
        }
    }

    handleOnBack() {
        if(this.state.phase === PHASES.blockLevelSelecting) {
            if(this.state.challengeSelected === 'Empty Project' || this.state.projectTypeSelected !== this.props.vm.ProjectTypes.CUSTOM) return this.setState({
                phase: PHASES.challengeSelecting,
                projectTypeSelected: null,
                challengeSelected: null
            })
            return this.setState({ //go back normal
                phase: PHASES.projectTypeSelecting,
                projectTypeSelected: null,
            })            
        }
        if(this.state.phase === PHASES.projectTypeSelecting) {
            return this.setState({
                phase: PHASES.challengeSelecting,
                projectTypeSelected: null,
                challengeSelected: null
            })
        }
        if(this.state.phase === PHASES.challengeSelecting) {
            if(this.state.codingTypeSelected === 'Code a Scratch Game') return this.setState({
                phase: PHASES.codingTypeSelecting,
                hardwareSelected: null,
                challengeSelected: null
            })
            
            return this.setState({
                phase: PHASES.hardwareSelecting,
                projectTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null
            })
        }
        if(this.state.phase === PHASES.hardwareSelecting) {
            return this.setState({
                phase: PHASES.codingTypeSelecting,
                projectTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null,
                gamepadSelected: null
            })
        }
        if(this.state.phase === PHASES.codingTypeSelecting) {
            return this.setState({
                phase: PHASES.gamepadSelecting,
                projectTypeSelected: null,
                codingTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null,
                gamepadSelected: null
            })
        }
    }

    handleClickGamepadType(gamepadType) {
        this.setState({
            phase: PHASES.codingTypeSelecting,
            gamepadSelected: gamepadType
        })
    }

    handleClickCodingType(codingType) {
        if(codingType === 'Code a Scratch Game') return this.setState({
            phase: PHASES.challengeSelecting,
            hardwareSelected: this.props.vm.HardwareTypes.no_hardware,
            codingTypeSelected: codingType
        })
    
        this.setState({
            phase: PHASES.hardwareSelecting,
            codingTypeSelected: codingType
        })
    }

    handleClickHardwareType(hardwareType) {
        this.setState({
            phase: PHASES.challengeSelecting,
            hardwareSelected: hardwareType
        })
    }

    handleClickProjectType(projectType) {
        this.setState({
            phase: PHASES.blockLevelSelecting,
            projectTypeSelected: projectType
        })
    }

    handleClickChallengeType(challengeType) {
        if(challengeType === 'Empty Project') return this.setState({
            phase: PHASES.blockLevelSelecting,
            challengeSelected: challengeType,
            projectTypeSelected: this.props.vm.ProjectTypes.CUSTOM
        })

        this.setState({
            phase: PHASES.projectTypeSelecting,
            challengeSelected: challengeType
        })
    }

    handleClickExampleType(exampleType) {
        this.setState({
            phase: PHASES.blockLevelSelecting,
            challengeSelected: exampleType,
            projectTypeSelected: this.props.vm.ProjectTypes.EXAMPLE
        })
    }

    handleClickBlockLevel(blockLevel) {
        if(this.state.projectTypeSelected === this.props.vm.ProjectTypes.DEMO) {
            const newProjectLink = `${window.location.origin}/#/newProject/${this.state.gamepadSelected}/${this.state.hardwareSelected}/${this.state.challengeSelected}/${this.state.projectTypeSelected}/${blockLevel}?openDemoMode=true`;

            window.open(newProjectLink, '_blank').focus();
        } else {
            this.props.setProjectTitle('Untitled Project')

        this.props.vm.loadExampleScratchLinkProject(this.state.gamepadSelected, this.state.hardwareSelected,  this.state.challengeSelected, this.state.projectTypeSelected, blockLevel);
        }
       
        this.setState({
            phase: PHASES.hardwareSelecting,
            gamepadSelected: null,
            hardwareSelected: null,
            challengeSelected: null,
            projectTypeSelected: null
        });
        this.props.onCancel();
    }

    render () {
        let title = 'New Project | Choose Gamepad Type';
        if(this.state.phase === PHASES.hardwareSelecting) title = 'Choose ScratchLink Hardware Type'
        if(this.state.phase === PHASES.codingTypeSelecting) title = 'Choose Coding Type'
        if(this.state.phase === PHASES.challengeSelecting) title = `Choose Challenge`
        if(this.state.phase === PHASES.projectTypeSelecting) title = `Choose Project Type`
        if(this.state.phase === PHASES.blockLevelSelecting) title = `Choose Level`

        return (
            <ProjectLoadModalComponent
                name={title}
                phase={this.state.phase}
                extension={this.state.extension}
                smallPeripheralImage={extensionData.find(ext => ext.extensionId === 'ScratchLink').smallPeripheralImage}
                vm={this.props.vm}
                gamepadSelected={this.state.gamepadSelected}
                codingTypeSelected={this.state.codingTypeSelected}
                hardwareSelected={this.state.hardwareSelected}
                challengeSelected={this.state.challengeSelected}
                projectTypeSelected={this.state.projectTypeSelected}
                currentProfile={this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.currentProfile}
                hardwareImages={this.state.hardwareImages}
                handleClickGamepadType={this.handleClickGamepadType}
                handleClickCodingType={this.handleClickCodingType}
                handleClickHardwareType={this.handleClickHardwareType}
                handleClickExampleType={this.handleClickExampleType}
                handleClickChallengeType={this.handleClickChallengeType}
                handleClickProjectType={this.handleClickProjectType}
                handleClickBlockLevel={this.handleClickBlockLevel}
                onCancel={this.props.onCancel}
                onBack={this.handleOnBack}
            />
        );
    }
}

ProjectLoadModal.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeProjectLoadModal());
    },
    onEmptyProject: () => {
        dispatch(requestNewProject());
    },
    setProjectTitle: (title) => dispatch(setProjectTitle(title))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLoadModal);