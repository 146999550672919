import React from 'react';

const ControllerIcon = ({ color, number }) =>  {
    if(number === 1) return (
        <svg fill={color} width="24.77" height="22.04" viewBox="0 0 24.77 22.04">
            <path d="M18.69,9.79A.82.82,0,1,1,17.88,9,.82.82,0,0,1,18.69,9.79Zm-2.44,2.44a.81.81,0,1,0-.81-.81A.81.81,0,0,0,16.25,12.23Zm4.06-.81a.81.81,0,1,1-.81-.81A.81.81,0,0,1,20.31,11.42Zm-2.43,2.44a.82.82,0,1,0-.82-.82A.82.82,0,0,0,17.88,13.86ZM7.31,9H8.94v1.63h1.62v1.62H8.94v1.63H7.31V12.23H5.69V10.61H7.31Z" transform="translate(-0.61 -3.43)"/>
            <path d="M5,5.3a.81.81,0,0,1,.57-1h0l3.14-.84a.81.81,0,0,1,1,.58.43.43,0,0,1,0,0A29.79,29.79,0,0,1,13,3.9a27.52,27.52,0,0,1,3.44.21.83.83,0,0,1,.94-.67l.08,0,3.14.84a.81.81,0,0,1,.57,1,.77.77,0,0,1-.08.19A3.23,3.23,0,0,1,21.7,6a9.54,9.54,0,0,1,1.78,2.88,24.17,24.17,0,0,1,1.31,4,22.06,22.06,0,0,1,.59,4.23,8.44,8.44,0,0,1-.54,3.59A2.29,2.29,0,0,1,22.5,22,4.12,4.12,0,0,1,20,20.77a15.44,15.44,0,0,1-1.2-1.31l-.6-.69A6.51,6.51,0,0,0,13,16.29a6.51,6.51,0,0,0-5.24,2.48l-.6.69A15.44,15.44,0,0,1,6,20.77,4.12,4.12,0,0,1,3.5,22a2.29,2.29,0,0,1-2.34-1.35,8.46,8.46,0,0,1-.54-3.59,22.06,22.06,0,0,1,.59-4.23,24.17,24.17,0,0,1,1.31-4A9.54,9.54,0,0,1,4.3,6,4.14,4.14,0,0,1,5,5.42.56.56,0,0,1,5,5.3ZM8.27,6A6,6,0,0,0,5.45,7.12,8.24,8.24,0,0,0,4,9.5,22,22,0,0,0,2.8,13.23a19.09,19.09,0,0,0-.55,3.91A7,7,0,0,0,2.64,20a.67.67,0,0,0,.69.39,2.55,2.55,0,0,0,1.51-.81,13.69,13.69,0,0,0,1-1.1l.72-.82a8.08,8.08,0,0,1,6.44-3,8.08,8.08,0,0,1,6.44,3l.72.82a13.69,13.69,0,0,0,1,1.1,2.5,2.5,0,0,0,1.51.81.66.66,0,0,0,.69-.39,6.93,6.93,0,0,0,.39-2.88,19.09,19.09,0,0,0-.55-3.91A22,22,0,0,0,22,9.5a8.1,8.1,0,0,0-1.44-2.38A6,6,0,0,0,17.73,6,23.78,23.78,0,0,0,13,5.53,23.78,23.78,0,0,0,8.27,6Z" transform="translate(-0.61 -3.43)"/>
            <path d="M14.06,25.47h-.93V19.56a5.17,5.17,0,0,1-.87.64,6.07,6.07,0,0,1-1,.47v-.89a5.71,5.71,0,0,0,1.36-.89,3.4,3.4,0,0,0,.82-1h.6Z" transform="translate(-0.61 -3.43)"/>
        </svg>
    )

    if(number === 2) return (
        <svg fill={color} width="24.77" height="22.04" viewBox="0 0 24.77 22.04">
            <path d="M18.69,9.79A.82.82,0,1,1,17.88,9,.82.82,0,0,1,18.69,9.79Zm-2.44,2.44a.81.81,0,1,0-.81-.81A.81.81,0,0,0,16.25,12.23Zm4.06-.81a.81.81,0,1,1-.81-.81A.81.81,0,0,1,20.31,11.42Zm-2.43,2.44a.82.82,0,1,0-.82-.82A.82.82,0,0,0,17.88,13.86ZM7.31,9H8.94v1.63h1.62v1.62H8.94v1.63H7.31V12.23H5.69V10.61H7.31Z" transform="translate(-0.61 -3.43)"/>
            <path d="M5,5.3a.81.81,0,0,1,.57-1h0l3.14-.84a.81.81,0,0,1,1,.58.43.43,0,0,1,0,0A29.79,29.79,0,0,1,13,3.9a27.52,27.52,0,0,1,3.44.21.83.83,0,0,1,.94-.67l.08,0,3.14.84a.81.81,0,0,1,.57,1,.77.77,0,0,1-.08.19A3.23,3.23,0,0,1,21.7,6a9.54,9.54,0,0,1,1.78,2.88,24.17,24.17,0,0,1,1.31,4,22.06,22.06,0,0,1,.59,4.23,8.44,8.44,0,0,1-.54,3.59A2.29,2.29,0,0,1,22.5,22,4.12,4.12,0,0,1,20,20.77a15.44,15.44,0,0,1-1.2-1.31l-.6-.69A6.51,6.51,0,0,0,13,16.29a6.51,6.51,0,0,0-5.24,2.48l-.6.69A15.44,15.44,0,0,1,6,20.77,4.12,4.12,0,0,1,3.5,22a2.29,2.29,0,0,1-2.34-1.35,8.46,8.46,0,0,1-.54-3.59,22.06,22.06,0,0,1,.59-4.23,24.17,24.17,0,0,1,1.31-4A9.54,9.54,0,0,1,4.3,6,4.14,4.14,0,0,1,5,5.42.56.56,0,0,1,5,5.3ZM8.27,6A6,6,0,0,0,5.45,7.12,8.24,8.24,0,0,0,4,9.5,22,22,0,0,0,2.8,13.23a19.09,19.09,0,0,0-.55,3.91A7,7,0,0,0,2.64,20a.67.67,0,0,0,.69.39,2.55,2.55,0,0,0,1.51-.81,13.69,13.69,0,0,0,1-1.1l.72-.82a8.08,8.08,0,0,1,6.44-3,8.08,8.08,0,0,1,6.44,3l.72.82a13.69,13.69,0,0,0,1,1.1,2.5,2.5,0,0,0,1.51.81.66.66,0,0,0,.69-.39,6.93,6.93,0,0,0,.39-2.88,19.09,19.09,0,0,0-.55-3.91A22,22,0,0,0,22,9.5a8.1,8.1,0,0,0-1.44-2.38A6,6,0,0,0,17.73,6,23.78,23.78,0,0,0,13,5.53,23.78,23.78,0,0,0,8.27,6Z" transform="translate(-0.61 -3.43)"/>
            <path d="M15.44,24.58v.89h-5a1.59,1.59,0,0,1,.11-.65,3.24,3.24,0,0,1,.61-1,9.93,9.93,0,0,1,1.21-1.15,10.06,10.06,0,0,0,1.67-1.6A1.88,1.88,0,0,0,14.48,20a1.24,1.24,0,0,0-.39-.93,1.45,1.45,0,0,0-1-.38,1.42,1.42,0,0,0-1.07.4,1.5,1.5,0,0,0-.41,1.11l-1-.09a2.38,2.38,0,0,1,.74-1.63,2.54,2.54,0,0,1,1.72-.56,2.4,2.4,0,0,1,1.72.6,2,2,0,0,1,.63,1.5,2.32,2.32,0,0,1-.18.89,3.54,3.54,0,0,1-.62.92,13.69,13.69,0,0,1-1.43,1.33c-.55.47-.91.79-1.07.95a2.89,2.89,0,0,0-.39.51Z" transform="translate(-0.61 -3.43)"/>
        </svg>
    )
};

export {
    ControllerIcon as default
};