import React from 'react';
import ReactTooltip from 'react-tooltip';
import ControllerIcon from './controller-icon.jsx';

import styles from './gamepad-connection.css';

const controllerColor = {
    connected: "#4CBF56",
    disconnected: "#8F8F8F",
    warning: "#FFCA3A"
};

class GamePadConnectionComponent extends React.Component {
    constructor(props) {
        super(props);

        const GamepadTypes = this.props.vm.GamepadTypes;
        const controllers = {
            "Vendor: 0810 Product: 0001": GamepadTypes.xbox,
            "Vendor: 1949 Product: 0402": GamepadTypes.t3,
            "Vendor: 081f Product: e401": GamepadTypes.retro
        };

        this.state = {
            color1: controllerColor.disconnected,
            color2: controllerColor.disconnected,
            controller1ToolTip: 'Not Connected',
            controller2ToolTip: 'Not Connected',
            controller1Name : null,
            controller2Name : null
        }

        const getGamepadId = (fullId) => {
            return fullId.substring(fullId.indexOf('(') + 1, fullId.indexOf(')')) || null;
        }

        const getGamepadName = (fullId) => {
            if(fullId === null) return null;

            return controllers[getGamepadId(fullId)] || null;
        }

        const getToolTipText = (fullId) => {
            if(!fullId) return 'Not Connected';

            return `${getGamepadName(fullId)} Connected` || 'Unknown Controller';
        }

        setInterval(() => {
            const gamepads = navigator.getGamepads();
    
            for(let i=0; i<2; i++) {
                const gamepad = gamepads[i];

                let color = controllerColor[gamepad ? 'connected' : 'disconnected'];
                let toolTip = getToolTipText(gamepad ? gamepad.id : null);

                if(gamepad && getGamepadId(gamepad.id) === "Vendor: 0810 Product: 0001") { //Wired USB Gamepad plugged in
                    const currentColor = this.state[`color${i+1}`];
                    const currentToolTip = this.state[`controller${i+1}ToolTip`];

                    color = currentColor;
                    toolTip = currentToolTip;

                    if(gamepad.axes[0].toFixed(5) === "-0.00392") { //Default is a warning state
                        color = controllerColor.warning;
                        toolTip = "Please turn on controller with the silver button";
                    }

                    if(currentColor !== controllerColor.connected && gamepad.axes[0].toFixed(5) === "0.00392") { //Overide the warning state to on when the silver button is pressed
                        color = controllerColor.connected;
                        toolTip = getToolTipText(gamepad.id);
                    }
                }

                const gamepadName = getGamepadName(gamepad ? gamepad.id : null);
                if(gamepadName !== this.state[`controller${i+1}Name`]) this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.updateGamepadDetectedMode({
                    [`gamepad${i+1}Mode`]: gamepadName || this.props.vm.GamepadTypes.no_gamepad,
                });

                this.setState({
                    [`color${i+1}`]: color,
                    [`controller${i+1}ToolTip`]: toolTip,
                    [`controller${i+1}Name`]: gamepadName
                });
            }
        }, 200);
    }

    render() {
        return (
            <div className={styles.gamepadConnection}>
                <div data-for="gamepadIcon1" data-tip={this.state.controller1ToolTip} className={styles.controllerIcon}>
                    <ControllerIcon color={this.state.color1} number={1} />
                </div>
                <div data-for="gamepadIcon2" data-tip={this.state.controller2ToolTip} className={styles.controllerIcon}>
                    <ControllerIcon color={this.state.color2} number={2} />
                </div>
                <ReactTooltip
                    className={styles.gamepadConnectionTooltip}
                    effect="solid"
                    id="gamepadIcon1"
                    place={'bottom'}
                />
                <ReactTooltip
                    className={styles.gamepadConnectionTooltip}
                    effect="solid"
                    id="gamepadIcon2"
                    place={'bottom'}
                />
            </div>
        )
    }
}

export {
    GamePadConnectionComponent as default
};