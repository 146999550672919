const SET_EXTENSION_LOADED = 'scratch-gui/scratchlink/SET_EXTENSION_LOADED';

const initialState = {
    extensionLoaded: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_EXTENSION_LOADED:
        return {
            extensionLoaded: action.extensionLoaded,
        };
    default:
        return state;
    }
};

const setExtensionLoaded = function (extensionLoaded) {
    return {
        type: SET_EXTENSION_LOADED,
        extensionLoaded
    };
};

export {
    reducer as default,
    initialState as scratchlinkInitialState,
    setExtensionLoaded
};
