exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".meter_green_1TDyl {\r\n    fill: rgb(171, 220, 170);\r\n    stroke: rgb(174, 211, 168);\r\n}\r\n\r\n.meter_yellow_YVGHr {\r\n    fill: rgb(251, 219, 130);\r\n    stroke: rgb(239, 212, 134);\r\n}\r\n\r\n.meter_red_S3fdX {\r\n    fill: rgb(251, 194, 142);\r\n    stroke: rgb(235, 189, 142);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"green": "meter_green_1TDyl",
	"yellow": "meter_yellow_YVGHr",
	"red": "meter_red_S3fdX"
};