import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import bindAll from 'lodash.bindall';
import Box from '../box/box.jsx';

import styles from './project-load-modal.css';

class ProjectLoadTile extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOnClick'
        ]);
    }
    handleOnClick () {
        this.props.onClick(this.props.name);
    }
    render () {
        return (
            <Box className={styles.projectLoadTile} onClick={this.handleOnClick}>
                <Box className={styles.projectLoadTileNameText}>
                    {this.props.name}
                </Box>
            </Box>
        );
    }
}

ProjectLoadTile.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
};

export default ProjectLoadTile;
