import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Box from '../box/box.jsx';
import Input from '../forms/input.jsx';

import styles from './connection-modal.css';

const ScratchLinkScanningStep = props => {
    const handleHTTPSProtocolSwitch = () => {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

    const handleHTTPProtocolSwitch = () => {
        location.replace(`http:${location.href.substring(location.protocol.length)}`);
    }

    return (
        <Box className={styles.body}>
            <Box className={styles.topArea}>
                <p><b>eBot's Hotspot WIFI Connection:</b> Join eBot's Hotspot on your device - enter "192.168.5.1" as the IP - click to connect.</p>
                <p><b>Wifi Network IP Connect:</b> Join the same network as eBot - obtain eBot's IP on the network - enter IP into "Wifi Network IP Connect" box - click to connect.</p>
                <p><b>USB Cable & Modem:</b> Ensure your device shows ScratchLink Device as a COM port - install CP210x Driver if needed- see Help if no COM port shows.</p>
                <p style={{marginTop: 10}}>Make sure that you have the USB driver installed for your eBot and are using Chrome or Edge.</p>
                <p>If you do not have the USB driver already, it can be installed here:</p>
                <p>
                    <a href="https://scratchlink.au/wp-content/uploads/2023/04/CP210x_Windows_Drivers.zip" target="_blank">Windows Download</a>
                    <a style={{marginLeft: 10}} href="https://scratchlink.au/wp-content/uploads/2023/04/Mac_OSX_VCP_Driver.zip" target="_blank">Mac Download</a>
                </p>
            </Box>
            <Box className={styles.bottomArea}>
                {
                    props.connectionPhase === 'connect' && (
                        <>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={() => props.handleConnectionTypeClick('hotspot')}
                            >
                                eBot Wifi Hotspot (http site)
                            </button>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={() => props.handleConnectionTypeClick('wifi')}
                            >
                                Wifi Network IP Connect (http site)
                            </button>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={() => props.handleConnectionTypeClick('serial')}
                            >
                                USB Cable (https site)
                            </button>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={() => props.handleConnectionTypeClick('modem')}
                            >
                                USB Modem (https site)
                            </button>
                        </>
                    )
                }
                {
                    props.connectionPhase === 'directIp' && (
                        <>
                            <div className={styles.bottomAreaDirectIp}>
                                <Input
                                    className={classNames(styles.ipInput)}
                                    maxLength="100"
                                    placeholder=""
                                    tabIndex="0"
                                    type="text"
                                    value={props.extensionRef._peripheral.connectIP}
                                    onChange={(e) => props.handleChangeIp(e.target.value)}
                                />
                                <button
                                    className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                    onClick={props.onConnecting}
                                >
                                    Wifi Network IP Connect
                                </button>
                            </div>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleBackPhaseClick}
                            >
                                Back
                            </button>
                        </>
                    )
                }
                {
                    props.connectionPhase === 'changeProtocolHTTP' && (
                        <>
                            <button
                                    className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                    onClick={handleHTTPProtocolSwitch}
                                >
                                Go To Wifi Site (http)
                            </button>
                            <p>Please save your existing project before switching to WIFI (go back now) or continue for new projects</p>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleBackPhaseClick}
                            >
                                Back
                            </button>
                        </>
                    )
                }
                {
                    props.connectionPhase === 'changeProtocolHTTPS' && (
                        <>
                            <button
                                    className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                    onClick={handleHTTPSProtocolSwitch}
                                >
                                Go To USB Site (https)
                            </button>
                            <p>Please save your existing project before switching to USB (go back now) or continue for new projects</p>
                            <button
                                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                                onClick={props.handleBackPhaseClick}
                            >
                                Back
                            </button>
                        </>
                    )
                }

            </Box>
        </Box>
    );
}

ScratchLinkScanningStep.propTypes = {
    onConnecting: PropTypes.func,
    onRefresh: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    peripheralList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        rssi: PropTypes.number,
        peripheralId: PropTypes.string
    })),
    scanning: PropTypes.bool.isRequired,
    smallPeripheralImage: PropTypes.string,
    extensionRef: PropTypes.object,
    handleChangeIp: PropTypes.func,
    handleSerialConnect: PropTypes.func,
    handleModemConnect: PropTypes.func,
    developmentMode: PropTypes.string
};

ScratchLinkScanningStep.defaultProps = {
    peripheralList: [],
    scanning: true
};

export default ScratchLinkScanningStep;
