const SET_FULL_SCREEN = 'scratch-gui/mode/SET_FULL_SCREEN';
const SET_PLAYER = 'scratch-gui/mode/SET_PLAYER';
const SET_DEMO_MODE = 'scratch-gui/mode/SET_DEMO_MODE';

const initialState = {
    showBranding: false,
    isFullScreen: false,
    isDemoMode: false,
    isPlayerOnly: false,
    hasEverEnteredEditor: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_FULL_SCREEN:
        return {
            isFullScreen: action.isFullScreen,
            isDemoMode: state.isDemoMode,
            isPlayerOnly: state.isPlayerOnly
        };
    case SET_DEMO_MODE:
        return {
            isFullScreen: state.isFullScreen,
            isDemoMode: action.isDemoMode,
            isPlayerOnly: state.isPlayerOnly
        };
    case SET_PLAYER:
        return {
            isFullScreen: state.isFullScreen,
            isDemoMode: state.isDemoMode,
            isPlayerOnly: action.isPlayerOnly,
            hasEverEnteredEditor: state.hasEverEnteredEditor || !action.isPlayerOnly
        };
    default:
        return state;
    }
};

const setFullScreen = function (isFullScreen) {
    return {
        type: SET_FULL_SCREEN,
        isFullScreen: isFullScreen
    };
};
const setDemoMode = function (isDemoMode) {
    return {
        type: SET_DEMO_MODE,
        isDemoMode: isDemoMode
    };
};
const setPlayer = function (isPlayerOnly) {
    return {
        type: SET_PLAYER,
        isPlayerOnly: isPlayerOnly
    };
};

export {
    reducer as default,
    initialState as modeInitialState,
    setFullScreen,
    setDemoMode,
    setPlayer
};
