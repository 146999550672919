import PropTypes from 'prop-types';
import React from 'react';

import blockLevelStyles from  './block-level-mode.css';
import menubarStyles from './menu-bar.css';

const styles = {
    ...blockLevelStyles,
    ...menubarStyles
}

class BlockLevelModeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.allBlockLevelTypes = props.vm.runtime.peripheralExtensions.ScratchLink._extension.BLOCK_LEVEL_TYPES;

        this.state = {
            blockLevelMode: props.vm.runtime.peripheralExtensions.ScratchLink._extension.blockLevelMode
        };

        this.handleChange = this.handleChange.bind(this);
        this.onProjectChanged = this.onProjectChanged.bind(this);
    }

    componentDidMount() {
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
    }

    onProjectChanged() {
        this.setState({
            blockLevelMode: this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.blockLevelMode
        })
    }

    handleChange(e) {
        this.setState({
            blockLevelMode: e.target.value
        });
        this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.changeBlockLevelMode(e.target.value);
        this.props.onSelectionChanged();
    }

    render() {
        let blockLevelTypes = Object.values(this.allBlockLevelTypes);
        if(!this.props.allowChange) {
            if(this.state.blockLevelMode === 'Core') blockLevelTypes.splice(0, 1);
            if(this.state.blockLevelMode === 'Advanced') blockLevelTypes.splice(0, 2);
        }

        return (
            <>
                <select
                    aria-label='Block Level Type'
                    className={styles.blockLevelModeSelect}
                    value={this.state.blockLevelMode}
                    onChange={this.handleChange}
                >
                    <option value="" hidden></option>
                    {
                        blockLevelTypes.map(blockLevelType => {
                            return (
                                <option key={blockLevelType} value={blockLevelType}>
                                    {blockLevelType}
                                </option>
                            )
                        })
                    }
                </select>
            </>
        )
    }
}

BlockLevelModeSelector.propTypes = {

};

export default BlockLevelModeSelector;