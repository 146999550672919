import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import bindAll from 'lodash.bindall';
import Box from '../box/box.jsx';

import styles from './project-load-modal.css';

import statusReadySvg from '../menu-bar/status-ready.svg';

class ProjectLoadHardwareTile extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOnClick'
        ]);
    }
    handleOnClick () {
        this.props.onClick(this.props.name);
    }
    render () {
        return (
            <Box className={styles.projectLoadHardwareTile} style={this.props.isCurrentProfile ? {background: '#5992FB', color: 'white'} : {background: this.props.backgroundColor || 'white', color: this.props.color || 'rgb(87, 94, 117)'}} onClick={this.handleOnClick}>
                {
                    this.props.smallImage !== '' && (
                        <img
                            className={styles.projectLoadTileImage}
                            src={this.props.smallImage}
                        />
                    )
                }
                <Box className={styles.projectLoadTileNameText}>
                    {
                        this.props.isCurrentProfile && (
                            <img
                                alt="Connected"
                                draggable={false}
                                src={statusReadySvg}
                                style={{marginRight: '10px'}}
                            />
                        )
                    }
                    {this.props.name}
                </Box>
            </Box>
        );
    }
}

ProjectLoadHardwareTile.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    smallImage: PropTypes.string,
    isCurrentProfile: PropTypes.bool
};

export default ProjectLoadHardwareTile;
