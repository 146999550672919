import React from 'react';
import PropTypes from 'prop-types';
import VM from 'scratch3-vm-scratchlink';
import {connect} from 'react-redux';

import styles from './gui.css';

class ProjectInfo extends React.Component{
    constructor(props) {
        super(props);

        this.onProjectChanged = this.onProjectChanged.bind(this);
    }

    componentDidMount() {
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
    }

    onProjectChanged() {
        this.forceUpdate();
    }

    render() {
        if(!this.props.scratchLinkExtensionLoaded) return <div></div>;

        let exampleProject = undefined; 
        let scratchLinkExtension = undefined;
        
        if(this.props.scratchLinkExtensionLoaded) {
            scratchLinkExtension = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension;
            try {
                exampleProject = this.props.vm.ExampleProjects[scratchLinkExtension.hardwareMode][scratchLinkExtension.challengeType][scratchLinkExtension.projectType][scratchLinkExtension.blockLevelMode];
            } catch(e) {
                exampleProject = {}
            }
        }

        const gamepadMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.gamepadMode;
        const hardwareMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.hardwareMode;
        const challengeType = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.challengeType;
        const blockLevelMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.blockLevelMode;
        const projectType = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.projectType;

        return (
            <div className={styles.projectInfo}>
                <div className={styles.projectInfoTop}>
                    {
                        scratchLinkExtension && (projectType === this.props.vm.ProjectTypes.CUSTOM || projectType === this.props.vm.ProjectTypes.CODINGPROJECT) && (
                            <h4 style={{marginBottom: 0}}>{gamepadMode} | {hardwareMode} | {blockLevelMode}</h4>
                        )
                    }
                    {
                        scratchLinkExtension && (projectType === this.props.vm.ProjectTypes.EXAMPLE || projectType === this.props.vm.ProjectTypes.DEMO) && (
                            <h4 style={{marginBottom: 0}}>{gamepadMode} | {hardwareMode} | {blockLevelMode} | {projectType} | {challengeType}</h4>
                        )
                    }
                    {
                        exampleProject && exampleProject.projectResourcesLink && (
                            <a className={styles.projectInfoHelpLink} href={exampleProject.projectResourcesLink} target="_blank"><h3 style={{margin: 0}}>Challenge Resources</h3></a>
                        )
                    }
                    <div style={{width: '100%', borderBottom: '1px solid', marginTop: '10px'}}/>
                </div>
                <div className={styles.projectInfoBody}>
                    <div>
                        {exampleProject && exampleProject.projectInfo ? exampleProject.projectInfo : ''}
                    </div>
                </div>
            </div>
        )
    }
}

ProjectInfo.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
}

const mapStateToProps = state => ({
    scratchLinkExtensionLoaded: state.scratchGui.scratchlink.extensionLoaded
})

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfo);