exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .hardware-mode_hardware-mode-select_2-pGL {\r\n    cursor: pointer;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    font-size: .75rem;\r\n    font-weight: bold;\r\n    border: 0;\r\n    color: white !important;\r\n    background: #242424 !important;\r\n} [dir=\"ltr\"] .hardware-mode_hardware-mode-select_2-pGL {\r\n    right: 0;\r\n} [dir=\"rtl\"] .hardware-mode_hardware-mode-select_2-pGL {\r\n    left: 0;\r\n} .hardware-mode_hardware-mode-select_2-pGL option {\r\n    opacity: 1;\r\n}", ""]);

// exports
exports.locals = {
	"hardware-mode-select": "hardware-mode_hardware-mode-select_2-pGL",
	"hardwareModeSelect": "hardware-mode_hardware-mode-select_2-pGL"
};