exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".stop-all_stop-all_15h2t {\r\n    width: 2rem;\r\n    height: 2rem;\r\n    padding: 0.375rem;\r\n    border-radius: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    cursor: pointer;\r\n    -webkit-transition: 0.2s ease-out;\r\n    -o-transition: 0.2s ease-out;\r\n    transition: 0.2s ease-out;\r\n}\r\n\r\n.stop-all_stop-all_15h2t {\r\n    opacity: 0.5;\r\n}\r\n\r\n.stop-all_stop-all_15h2t:hover {\r\n    -webkit-transform: scale(1.2);\r\n        -ms-transform: scale(1.2);\r\n            transform: scale(1.2);\r\n}\r\n\r\n.stop-all_stop-all_15h2t.stop-all_is-active_PEgCP {\r\n    opacity: 1;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"stop-all": "stop-all_stop-all_15h2t",
	"stopAll": "stop-all_stop-all_15h2t",
	"is-active": "stop-all_is-active_PEgCP",
	"isActive": "stop-all_is-active_PEgCP"
};