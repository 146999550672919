import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch3-vm-scratchlink';
import { Editor } from '@tinymce/tinymce-react';

import { connect } from 'react-redux';

class TinymceEditor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleTextChange'
        ]);

        this.editorRef = React.createRef(null);
    }

    handleTextChange(text) {
        this.props.vm.portfolioText = text;
    }
    
    render () {
        return (
            <Editor
                apiKey="far5meg4hpgddiu0sg1340k5chxl73a7xdyhbncyoh3kdxex"
                value={this.props.vm.portfolioText}
                ref={this.editorRef}
                init={{
                    width: '100%',
                    height: '100%',
                    skin: "oxide-dark",
                    menubar: 'edit view insert format tools table',
                    plugins: [
                        'preview', 'searchreplace', 'autolink', 'directionality', 'code', 'visualblocks', 
                        'visualchars', 'fullscreen', 'image', 'link', 'codesample', 
                        'table', 'charmap', 'pagebreak', 'nonbreaking', 'anchor', 
                        'insertdatetime', 'advlist', 'lists', 'wordcount', 
                        'charmap', 'quickbars', 'emoticons'
                    ],
                    toolbar: 'undo redo bold italic fontfamily blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat code image media link ltr rtl codesample',
                    codesample_languages: [
                        { text: 'JavaScript', value: 'javascript' },
                        { text: 'HTML', value: 'markup' }
                    ],
                    link_target_list: [
                        { text: 'Current window', value: '' }
                    ],
                    file_picker_types: 'image',
                    file_picker_callback: (cb) => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                    
                        input.addEventListener('change', (e) => {
                            if(!e.target || !e.target.files || !this.editorRef || !this.editorRef.current || !this.editorRef.current.editor) return;
                            const tinymce = this.editorRef.current.editor;
                            const file = e.target.files[0];
                        
                            const reader = new FileReader();
                            reader.addEventListener('load', () => {
                                if(!reader.result || typeof reader.result !== 'string') return;

                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache =  tinymce.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);
                        
                                cb(blobInfo.blobUri(), { title: file.name });
                            });
                            reader.readAsDataURL(file);
                        });
                    
                        input.click();
                    },
                    image_advtab: true,
                    link_assume_external_targets: true,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: #1e1e1e; color: white;}'
                }}
                onEditorChange={(value) => this.handleTextChange(value)}
            />
        );
    }
}

TinymceEditor.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TinymceEditor);
