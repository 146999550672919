import axios from 'axios';


class AutoFileURLOpen {
    constructor() {
        this.vm = null;
        this.urlHash = null;
    }

    init() {
        this.urlHash = window.location.hash;
    }

    setVM(vm) {
        this.vm = vm;
    }

    run(reduxSetFullscreen, reduxSetDemoMode) {
        if(!this.vm || !this.urlHash) return;

        if(this.urlHash.startsWith('#/remoteFile/')) {
            const fileName = this.urlHash.split('#/remoteFile/')[1];
            
            this.loadRemoteFile(fileName);
        }

        if(this.urlHash.startsWith('#/newProject/')) {
            const project = this.urlHash.split('#/newProject/')[1];

            const [
                gamepadType,
                hardwareType,
                challengeType,
                projectType,
                end
            ] = project.split('%20').join(' ').split('/');

            const blockLevelType = end.split('?')[0];

            this.vm.loadExampleScratchLinkProject(gamepadType, hardwareType, challengeType, projectType, blockLevelType);
        }

        if(this.urlHash.indexOf('?openFullscreen=true') !== -1) {
            reduxSetFullscreen(true);
        }

        if(this.urlHash.indexOf('?openDemoMode=true') !== -1) {
            reduxSetFullscreen(true);
            reduxSetDemoMode(true);
        }
    }
    
    loadRemoteFile(fileName) {
        axios.get(`https://us-central1-scratchlink-app.cloudfunctions.net/getStorageItem?userId=${fileName.substring(0, fileName.indexOf('/'))}&fileId=${fileName}`, {validateStatus: () => true}).then(firebaseFile => {
            if(firebaseFile.status === 400) return console.log('ERROR: An error occured opening or retrieving file');

            axios.get(`https://storage.scratchlink.codes/${fileName}`, {transformResponse: [], validateStatus: () => true, responseType: 'arraybuffer'}).then(file => {
                if(file.status === 400) {
                    this.clearUrl();
                    return;
                }

                this.vm.loadProject(file.data);
            })
        });
    }
}

const autoFileURLOpen = new AutoFileURLOpen();

export default autoFileURLOpen;