exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .dial_container_1JhHX {\r\n    padding: 1rem;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: center;\r\n    -webkit-justify-content: center;\r\n        -ms-flex-pack: center;\r\n            justify-content: center;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n} .dial_dial-container_33mkQ {\r\n    position: relative;\r\n} .dial_dial-face_2aWlr, .dial_dial-handle_1OHhg, .dial_gauge_3bwaY {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    overflow: visible;\r\n} .dial_dial-face_2aWlr {\r\n    width: 100%;\r\n} .dial_dial-handle_1OHhg {\r\n    cursor: pointer;\r\n    width: 40px;\r\n    height: 40px;\r\n    /* Use margin to make positioning via top/left easier */\r\n    margin-left: calc(40px / -2);\r\n    margin-top: calc(40px / -2);\r\n} .dial_gauge-path_VQesE {\r\n    fill: hsla(215, 100%, 65%, 0.35);\r\n    stroke: hsla(215, 100%, 65%, 1);\r\n    stroke-width: 1px;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"container": "dial_container_1JhHX",
	"dial-container": "dial_dial-container_33mkQ",
	"dialContainer": "dial_dial-container_33mkQ",
	"dial-face": "dial_dial-face_2aWlr",
	"dialFace": "dial_dial-face_2aWlr",
	"dial-handle": "dial_dial-handle_1OHhg",
	"dialHandle": "dial_dial-handle_1OHhg",
	"gauge": "dial_gauge_3bwaY",
	"gauge-path": "dial_gauge-path_VQesE",
	"gaugePath": "dial_gauge-path_VQesE"
};