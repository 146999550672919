import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../../containers/modal.jsx';
import keyMirror from 'keymirror';
import classNames from 'classnames';

import ProjectLoadTile from './project-load-tile.jsx';
import ProjectLoadHardwareTile from './project-load-hardware-tile.jsx';
import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import backIcon from './back.svg';

import styles from './project-load-modal.css';

const CODESCRATCHGAME = 'Code a Scratch Game';
const EBOTSIMULATOR = 'eBot Simulator - Code a Virtual Robot';
const CODEREALROBOTS = 'Code Real eBot Robots';

const PHASES = keyMirror({
    gamepadSelecting: null,
    codingTypeSelecting: null,
    hardwareSelecting: null,
    challengeSelecting: null,
    projectTypeSelecting: null,
    blockLevelSelecting: null
});

const ProjectLoadModalComponent = props => (
    <Modal
        contentLabel={props.name}
        className={styles.modalContent}
        headerClassName={styles.header}
        headerImage={props.smallPeripheralImage}
        id="projectLoadModal"
        onHelp={props.onHelp}
        onRequestClose={props.onCancel}
    >
        {
            props.phase === PHASES.gamepadSelecting && <ProjectLoadGamepadSelectingPhase {...props} />
        }
        {
            props.phase === PHASES.codingTypeSelecting && <ProjectLoadCodingTypeSelectingPhase {...props} />
        }
        {
            props.phase === PHASES.hardwareSelecting && <ProjectLoadHardwareSelectingPhase {...props} />
        }
        {
            props.phase === PHASES.challengeSelecting && <ProjectLoadChallengeSelectingPhase {...props} />
        }
        {
            props.phase === PHASES.projectTypeSelecting && <ProjectLoadProjectTypeSelectingPhase {...props} />
        }
        {
            props.phase === PHASES.blockLevelSelecting && <ProjectLoadBlockLevelSelectingPhase {...props} />
        }
    </Modal>
);

const ProjectLoadGamepadSelectingPhase = props => (
    <>
         <Box className={styles.body}>
            <Box className={styles.activityArea}>
                <div className={styles.projectLoadHardwareItems}>
                    <h3 className={styles.projectLoadModalTitles}>Gamepads</h3>
                    <div className={styles.projectLoadTilePane}>
                        <ProjectLoadHardwareTile
                            name={props.vm.GamepadTypes.no_gamepad}
                            smallImage={''} 
                            onClick={props.handleClickGamepadType}
                            isCurrentProfile={false}
                            backgroundColor={'#5CB1D6'}
                            color={'white'}
                        />
                        {
                            Object.keys(props.vm.GamepadExampleProjects).map(gamepadType => (
                                <ProjectLoadHardwareTile
                                    key={gamepadType}
                                    name={gamepadType}
                                    smallImage={''} 
                                    onClick={props.handleClickGamepadType}
                                    isCurrentProfile={false}
                                    backgroundColor={'#5CB1D6'}
                                    color={'white'}
                                />
                            ))
                        }
                    </div>
                </div>
            </Box>
        </Box>
        <Box className={styles.bottomArea} style={{boxShadow: '0px 5px 17px 7px rgba(0,0,0,0.05)'}}>
            <Dots className={styles.bottomAreaItem} total={6} counter={0} />
        </Box>
    </>
)

const ProjectLoadCodingTypeSelectingPhase = props => (
    <>
        <Box className={styles.body}>
            <Box className={styles.activityArea}>
                <div className={styles.projectLoadHardwareItems}>
                    <div className={styles.projectLoadTilePaneVertical}>
                        <ProjectLoadHardwareTile
                            name={CODESCRATCHGAME}
                            smallImage={''} 
                            onClick={props.handleClickCodingType}
                            isCurrentProfile={false}
                            backgroundColor={'#5CB1D6'}
                            color={'white'}
                        />
                        <ProjectLoadHardwareTile
                            name={EBOTSIMULATOR}
                            smallImage={''} 
                            onClick={props.handleClickCodingType}
                            isCurrentProfile={false}
                            backgroundColor={'#5CB1D6'}
                            color={'white'}
                        />
                        <ProjectLoadHardwareTile
                            name={CODEREALROBOTS}
                            smallImage={''} 
                            onClick={props.handleClickCodingType}
                            isCurrentProfile={false}
                            backgroundColor={'#5CB1D6'}
                            color={'white'}
                        />
                    </div>
                </div>
            </Box>
        </Box>
        <Box className={styles.bottomArea} style={{boxShadow: '0px 5px 17px 7px rgba(0,0,0,0.05)'}}>
            <Dots className={styles.bottomAreaItem} total={6} counter={1} />
            <button
                className={classNames(styles.bottomAreaItem, styles.backButton)}
                onClick={props.onBack}
            >
                <img
                    className={styles.buttonIconRight}
                    src={backIcon}
                />
                Back
            </button>
        </Box>
    </>
);

const ProjectLoadHardwareSelectingPhase = props => {
    if(props.codingTypeSelected === EBOTSIMULATOR) return (
        <>
            <Box className={styles.body}>
                <Box className={styles.activityArea}>
                    <div className={styles.projectLoadHardwareItems}>
                        <h3 className={styles.projectLoadModalTitles}>Simulators</h3>
                        <div className={styles.projectLoadTilePane}>
                            {
                                Object.keys(props.vm.ExampleProjects).filter(hardwareType => hardwareType.includes('Simulator')).map(hardwareType => (
                                    <ProjectLoadHardwareTile
                                        key={hardwareType}
                                        name={hardwareType}
                                        smallImage={props.hardwareImages[hardwareType] || ''} 
                                        onClick={props.handleClickHardwareType}
                                        isCurrentProfile={props.vm.DeviceProfileTypes[hardwareType] === props.currentProfile}
                                        backgroundColor={'#CF63CF'}
                                        color={'white'}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Box>
            </Box>
            <Box className={styles.bottomArea} style={{boxShadow: '0px 5px 17px 7px rgba(0,0,0,0.05)'}}>
                <Dots className={styles.bottomAreaItem} total={6} counter={2} />
                <button
                    className={classNames(styles.bottomAreaItem, styles.backButton)}
                    onClick={props.onBack}
                >
                    <img
                        className={styles.buttonIconRight}
                        src={backIcon}
                    />
                    Back
                </button>
            </Box>
        </>
    )

    return (
        <>
            <Box className={styles.body}>
                <Box className={styles.activityArea}>
                    <div className={styles.projectLoadHardwareItems}>
                        <h3 className={styles.projectLoadModalTitles}>Robotic Hardware</h3>
                        <div className={styles.projectLoadTilePane} style={{overflowY: 'visible'}}>
                            {
                                Object.keys(props.vm.ExampleProjects).filter(hardwareType => !hardwareType.includes('Simulator') && hardwareType !== props.vm.HardwareTypes.no_hardware).map(hardwareType => (
                                    <ProjectLoadHardwareTile
                                        key={hardwareType}
                                        name={hardwareType}
                                        smallImage={props.hardwareImages[hardwareType] || ''} 
                                        onClick={props.handleClickHardwareType}
                                        isCurrentProfile={props.vm.DeviceProfileTypes[hardwareType] === props.currentProfile}
                                        backgroundColor={'#5CB1D6'}
                                        color={'white'}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Box>
            </Box>
            <Box className={styles.bottomArea} style={{boxShadow: '0px 5px 17px 7px rgba(0,0,0,0.05)'}}>
                <Dots className={styles.bottomAreaItem} total={6} counter={2} />
                <button
                    className={classNames(styles.bottomAreaItem, styles.backButton)}
                    onClick={props.onBack}
                >
                    <img
                        className={styles.buttonIconRight}
                        src={backIcon}
                    />
                    Back
                </button>
            </Box>
        </>
    )
}

const ProjectLoadChallengeSelectingPhase = props => {
    let exampleChallenges = {}
    if(props.gamepadSelected !== props.vm.GamepadTypes.no_gamepad) {
        exampleChallenges = props.vm.ExampleProjects[props.hardwareSelected]
        //if(!exampleChallenges['Remote Control']) exampleChallenges['Remote Control'] = {}
    } else {
        exampleChallenges =  props.vm.ExampleProjects[props.hardwareSelected]; //Select gamepad example projects if using gamepad and no hardware
    }

    const examples = Object.keys(exampleChallenges).filter(challengeName => challengeName !== 'Empty Project' && exampleChallenges[challengeName][props.vm.ProjectTypes.EXAMPLE]);
    const hasExamples = examples.length > 0;

    const challenges = Object.keys(exampleChallenges).filter(challengeName => challengeName !== 'Empty Project' && !exampleChallenges[challengeName][props.vm.ProjectTypes.EXAMPLE]);
    const hasChallenges = challenges.length > 0;

    return (
        <>
            <Box className={styles.body}>
                <Box className={styles.activityArea}>
                    <div className={styles.projectLoadTilePane}>
                        <ProjectLoadTile
                            name="Empty Project"
                            onClick={props.handleClickChallengeType}      
                        />
                        {
                            hasExamples && (
                                <>
                                    <div style={{width: '100%', borderBottom: '1px solid', paddingTop: '10px'}} />
                                    <h3 className={styles.projectLoadModalTitles}>Examples</h3>
                                    {
                                        examples.map(exampleName => (
                                            <ProjectLoadTile
                                                key={exampleName}
                                                name={exampleName}
                                                onClick={props.handleClickExampleType}      
                                            />
                                        ))
                                    }
                                </>
                            )
                        }
                        {
                            hasChallenges && (
                                <>
                                    <div style={{width: '100%', borderBottom: '1px solid', paddingTop: '10px'}} />
                                    <h3 className={styles.projectLoadModalTitles}>Challenges</h3>
                                    {
                                        challenges.map(challengeName => (
                                            <ProjectLoadTile
                                                key={challengeName}
                                                name={challengeName}
                                                onClick={props.handleClickChallengeType}      
                                            />
                                        ))
                                    }
                                </>
                            )
                        }
                    </div>
                </Box>
            </Box>
            <Box className={styles.bottomArea}>
                <Dots className={styles.bottomAreaItem} total={6} counter={3} />
                <button
                    className={classNames(styles.bottomAreaItem, styles.backButton)}
                    onClick={props.onBack}
                >
                    <img
                        className={styles.buttonIconRight}
                        src={backIcon}
                    />
                    Back
                </button>
            </Box>
        </>
    )
}

const ProjectLoadProjectTypeSelectingPhase = props => {
    let exampleProjects = {}
    if(props.challengeSelected !== 'Remote Control' || (props.challengeSelected === 'Remote Control' && props.gamepadSelected === props.vm.GamepadTypes.no_gamepad) ) {
        exampleProjects = props.vm.ExampleProjects[props.hardwareSelected][props.challengeSelected];
    } else {
        exampleProjects = props.vm.GamepadExampleProjects[props.gamepadSelected];
    }

    const projectTypes = Object.keys(exampleProjects).filter(projectType => projectType !== props.vm.ProjectTypes.EXAMPLE);

    return (
        <>
            <Box className={styles.body}>
                <Box className={styles.activityArea}>
                    <div className={styles.projectLoadTilePane}>
                        {
                            projectTypes.map(projectType => (
                                <ProjectLoadTile
                                    key={projectType}
                                    name={projectType}
                                    onClick={props.handleClickProjectType}      
                                />
                            ))
                        }
                    </div>
                </Box>
            </Box>
            <Box className={styles.bottomArea}>
                <Dots className={styles.bottomAreaItem} total={6} counter={4} />
                <button
                    className={classNames(styles.bottomAreaItem, styles.backButton)}
                    onClick={props.onBack}
                >
                    <img
                        className={styles.buttonIconRight}
                        src={backIcon}
                    />
                    Back
                </button>
            </Box>
        </>
    )
}

const ProjectLoadBlockLevelSelectingPhase = props => {
    let exampleProjects = {}; //Select gamepad example projects if using gamepad and no hardware
    if(props.challengeSelected !== 'Remote Control' || (props.challengeSelected === 'Remote Control' && props.gamepadSelected === props.vm.GamepadTypes.no_gamepad) ) {
        exampleProjects = props.vm.ExampleProjects[props.hardwareSelected][props.challengeSelected][props.projectTypeSelected];
    } else {
        exampleProjects = props.vm.GamepadExampleProjects[props.gamepadSelected][props.projectTypeSelected];
    }

    return (
        <>
            <Box className={styles.body}>
                <Box className={styles.activityArea}>
                    <div className={styles.projectLoadTilePane}>
                        {
                            props.challengeSelected !== 'Empty Project' && Object.keys(exampleProjects).map(blockLevel => (
                                <ProjectLoadTile
                                    key={blockLevel}
                                    name={blockLevel}
                                    onClick={props.handleClickBlockLevel}      
                                />
                            ))
                        }
                        {
                            props.challengeSelected === 'Empty Project' && Object.keys(props.vm.BlockLevelTypes).map(blockLevel => (
                                <ProjectLoadTile
                                    key={blockLevel}
                                    name={props.vm.BlockLevelTypes[blockLevel]}
                                    onClick={props.handleClickBlockLevel}      
                                />
                            ))
                        }
                    </div>
                </Box>
            </Box>
            <Box className={styles.bottomArea}>
                <Dots className={styles.bottomAreaItem} total={6} counter={5} />
                <button
                    className={classNames(styles.bottomAreaItem, styles.backButton)}
                    onClick={props.onBack}
                >
                    <img
                        className={styles.buttonIconRight}
                        src={backIcon}
                    />
                    Back
                </button>
            </Box>
        </>
    )
}

ProjectLoadModalComponent.propTypes = {
    name: PropTypes.string,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    vm: PropTypes.object,
    extension: PropTypes.object,
    smallPeripheralImage: PropTypes.string,
    hardwareSelected: PropTypes.string,
    codingTypeSelected: PropTypes.string,
    challengeSelected: PropTypes.string,
    currentProfile: PropTypes.string,
    hardwareImages: PropTypes.object,
    handleClickHardwareType: PropTypes.func,
    handleClickCodingType: PropTypes.func,
    handleClickChallengeType: PropTypes.func,
    handleClickProjectType: PropTypes.func,
    handleClickBlockLevel: PropTypes.func,
    onCancel: PropTypes.func,
    onBack: PropTypes.func
};

export {
    ProjectLoadModalComponent as default,
    PHASES
};
