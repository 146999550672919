import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';

import styles from './file-modal.css';

class FileComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linkCopied: false,
            iframeCopied: false
        };

        bindAll(this, [
            'handleCopyLinkClick',
            'handleCopyIFrameClick'
        ]);
    }

    handleCopyLinkClick() {
        this.setState({
            linkCopied: true
        });

        navigator.clipboard.writeText(`${window.location.origin}/#/remoteFile/${this.props.file.fileName}`);

        setTimeout(() => this.setState({
            linkCopied: false
        }), 2500) //Reset
    }

    handleCopyIFrameClick() {
        this.setState({
            iframeCopied: true
        });

        navigator.clipboard.writeText(`<iframe src="${window.location.origin}/#/remoteFile/${this.props.file.fileName}?openFullscreen=true" height="1000" width="1000" title="Iframe Example"></iframe>`);

        setTimeout(() => this.setState({
            iframeCopied: false
        }), 2500) //Reset
    }

    render() {
        return (
            <div className={styles.file} key={this.props.file.fileName}>
                <div className={styles.fileName} onClick={() => this.props.handleClickFile(this.props.file.fileName, this.props.file.name)}>
                    {this.props.file.name}<span style={{color: '#555'}}>.{this.props.file.extension}</span>
                </div>
                <button className={styles.fileLink} onClick={this.handleCopyLinkClick}>
                    {
                        !this.state.linkCopied && <>Get Link</>
                    }
                    {
                        this.state.linkCopied && <>Copied!</>
                    }
                </button>
                <button style={{borderLeft: '1px solid #EEEEEE'}} className={styles.fileLink} onClick={this.handleCopyIFrameClick}>
                    {
                        !this.state.iframeCopied && <>Get Iframe</>
                    }
                    {
                        this.state.iframeCopied && <>Copied!</>
                    }
                </button>
            </div>
        )
    }
}

export {
    FileComponent as default
};