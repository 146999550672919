exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".gamepad-connection_gamepad-connection_2nP8u {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n}\r\n.gamepad-connection_controller-icon_3aMCm {\r\n    margin-left: 10px;\r\n}\r\n.gamepad-connection_gamepad-connection-tooltip_WXlUQ {\r\n    background-color: #4C97FF !important;\r\n    opacity: 1 !important;\r\n    z-index: 9999 !important;\r\n}\r\n.gamepad-connection_gamepad-connection-tooltip_WXlUQ:after {\r\n    content: \"\";\r\n    border-top: 1px solid hsla(0, 0%, 0%, .1) !important;\r\n    border-left: 0 !important;\r\n    border-bottom: 0 !important;\r\n    border-right: 1px solid hsla(0, 0%, 0%, .1) !important;\r\n    border-radius: 0.125rem;\r\n    height: 0.5rem !important;\r\n    width: 0.5rem !important;\r\n\r\n    margin-left: -0.25rem !important;\r\n    top: -0.25rem !important;\r\n    -webkit-transform: rotate(-45deg) !important;\r\n        -ms-transform: rotate(-45deg) !important;\r\n            transform: rotate(-45deg) !important;\r\n}", ""]);

// exports
exports.locals = {
	"gamepad-connection": "gamepad-connection_gamepad-connection_2nP8u",
	"gamepadConnection": "gamepad-connection_gamepad-connection_2nP8u",
	"controller-icon": "gamepad-connection_controller-icon_3aMCm",
	"controllerIcon": "gamepad-connection_controller-icon_3aMCm",
	"gamepad-connection-tooltip": "gamepad-connection_gamepad-connection-tooltip_WXlUQ",
	"gamepadConnectionTooltip": "gamepad-connection_gamepad-connection-tooltip_WXlUQ"
};