import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import FileModalComponent from '../components/file-modal/file-modal.jsx';
import VM from 'scratch3-vm-scratchlink';
import axios from 'axios';
import { connect } from 'react-redux';
import { closeFileModal } from '../reducers/modals';

import {
    setProjectTitle
} from '../reducers/project-title';

import firebaseStorage from '../lib/firebaseStorage';
import { ref, uploadBytes } from "firebase/storage";

class FileModal extends React.Component {
    constructor (props) {
        super(props);

        bindAll(this, [
            'handleOnCancel',
            'handleSearchUserChange',
            'handleOnSearch',
            'handleClickFile',
            'handleOnExistingUserSave',
            'handleOnNewUserSave',
            'handleOnClickCreateUser',
            'handleOnClickBack'
        ]);

        this.state = {
            searchUser: '',
            files: [],
            loadingFile: false,
            newUser: false,
            fileError: ''
        };
    }

    handleOnCancel() {
        this.setState({
            fileError: ''
        });
        this.props.onCancel()
    }

    handleSearchUserChange(e) {
        this.setState({
            searchUser: e.target.value,
            files: []
        })
    }

    getFirebaseLink() {
        //if(this.props.vm.runtime.developmentMode) return 'http://localhost:5001/scratchlink-app/us-central1/';

        return 'https://us-central1-scratchlink-app.cloudfunctions.net/';
    }

    handleOnSearch() {
        axios.get(`${this.getFirebaseLink()}getUserStorageList?userId=${this.state.searchUser}`, {validateStatus: () => true}).then(firebaseFiles => {
            if(firebaseFiles.status === 400) {
                this.setState({
                    fileError: 'Could not find user'
                });
                return;
            }

            this.setState({
                files: firebaseFiles.data.files,
                fileError: ''
            })
        })
    }

    handleClickFile(fileName, name) {
        this.setState({
            loadingFile: true
        });
        axios.get(`${this.getFirebaseLink()}getStorageItem?userId=${fileName.substring(0, fileName.indexOf('/'))}&fileId=${fileName}`, {validateStatus: () => true}).then(firebaseFile => {
            if(firebaseFile.status === 400) return console.log('ERROR: An error occured opening or retrieving file');

            axios.get(firebaseFile.data.file, {transformResponse: [], validateStatus: () => true, responseType: 'arraybuffer'}).then(file => {
                if(file.status === 400) return console.log('ERROR: An error occured opening or retrieving file');

                this.props.vm.loadProject(file.data);
                this.setState({
                    loadingFile: false
                });
                this.props.setProjectTitle(name)
                this.props.onCancel();
            })
        });
    }

    handleOnExistingUserSave() {
        axios.get(`${this.getFirebaseLink()}getUserStorageList?userId=${this.state.searchUser}`, {validateStatus: () => true}).then(firebaseFiles => {
            if(firebaseFiles.status === 400) {
                this.setState({
                    fileError: 'User does not exist'
                });
                return;
            }

            this.props.vm.saveProjectSb3().then(content => {
                try {
                    const blob = new Blob([content], {type: "application/zip"});
                    const storageRef = ref(firebaseStorage, `${this.state.searchUser}/${this.props.fileName}.sb3`);
            
                   uploadBytes(storageRef, blob).then(snapshot => {
                       this.props.onCancel();
                   });
                } catch(e) {
                    console.error(`ERROR: Could not save file. Check console for more information.`);
                    console.error(e);
                    return;
                }
            })
        });
    }

    handleOnNewUserSave() {
        axios.get(`${this.getFirebaseLink()}getUserStorageList?userId=${this.state.searchUser}`, {validateStatus: () => true}).then(firebaseFiles => {
            if(firebaseFiles.status === 200 && firebaseFiles.data.files.length > 0) {
                this.setState({
                    fileError: 'User already exists'
                });
                return;
            }

            this.props.vm.saveProjectSb3().then(content => {
                try {
                    const blob = new Blob([content], {type: "application/zip"});
                    const storageRef = ref(firebaseStorage, `${this.state.searchUser}/${this.props.fileName}.sb3`);
            
                   uploadBytes(storageRef, blob).then(snapshot => {
                       this.props.onCancel();
                   });
                } catch(e) {
                    console.error(`ERROR: Could not save file. Check console for more information.`);
                    console.error(e);
                    return;
                }
            })
        });
    }

    handleOnClickCreateUser() {
        this.setState({
            newUser: true,
            fileError: ''
        })
    }

    handleOnClickBack() {
        this.setState({
            newUser: false,
            fileError: ''
        })
    }

    render () {
        return (
            <FileModalComponent
                vm={this.props.vm}
                onCancel={this.handleOnCancel}
                searchUser={this.state.searchUser}
                handleSearchUserChange={this.handleSearchUserChange}
                handleOnSearch={this.handleOnSearch}
                files={this.state.files}
                handleClickFile={this.handleClickFile}
                phase={this.props.vm.fileModalPhase}
                handleOnExistingUserSave={this.handleOnExistingUserSave}
                handleOnNewUserSave={this.handleOnNewUserSave}
                handleOnClickCreateUser={this.handleOnClickCreateUser}
                handleOnClickBack={this.handleOnClickBack}
                newUser={this.state.newUser}
                loadingFile={this.state.loadingFile}
                fileError={this.state.fileError}
                setProjectTitle={this.props.setProjectTitle}
                projectTitle={this.props.projectTitle}
            />
        );
    }
}

FileModal.propTypes = {
    extensionId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.connectionModal.extensionId,
    fileName: state.scratchGui.projectTitle,
    projectTitle: state.scratchGui.projectTitle,
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeFileModal());
    },
    setProjectTitle: (title) => dispatch(setProjectTitle(title))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileModal);
