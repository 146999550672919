import PropTypes from 'prop-types';
import React from 'react';

import hardwareStyles from  './hardware-mode.css';
import menubarStyles from './menu-bar.css';

const styles = {
    ...hardwareStyles,
    ...menubarStyles
}

class HardwareModeSelector extends React.Component {
    constructor(props) {
        super(props);

        this.hardwareTypes = props.vm.runtime.peripheralExtensions.ScratchLink._extension.HARDWARE_TYPES;

        this.state = {
            hardwareMode: props.vm.runtime.peripheralExtensions.ScratchLink._extension.hardwareMode
        };

        this.handleChange = this.handleChange.bind(this);
        this.onProjectChanged = this.onProjectChanged.bind(this);
    }

    componentDidMount() {
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
    }

    onProjectChanged() {
        this.setState({
            hardwareMode: this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.hardwareMode
        })
    }

    handleChange(e) {
        this.setState({
            hardwareMode: e.target.value
        });
        this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.changeHardwareMode(e.target.value);
        this.props.onSelectionChanged();
    }

    render() {
        if(this.state.hardwareMode.includes('Simulator')) {
            return <span style={{margin: '0 5px'}}>{this.state.hardwareMode}</span>
        }

        return (
            <select
                aria-label='Hardware Type'
                className={styles.hardwareModeSelect}
                value={this.state.hardwareMode}
                onChange={this.handleChange}
            >
                <option value="" hidden></option>
                {
                    Object.keys(this.hardwareTypes).filter(hardwareType => !this.hardwareTypes[hardwareType].includes('Simulator')).map(hardwareType => {
                        return (
                            <option key={this.hardwareTypes[hardwareType]} value={this.hardwareTypes[hardwareType]}>
                                {this.hardwareTypes[hardwareType]}
                            </option>
                        )
                    })
                }
            </select>
        )
    }
}

HardwareModeSelector.propTypes = {

};

export default HardwareModeSelector;